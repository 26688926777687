import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: function () {
      return import("../views/AboutView.vue");
    },
  },
  {
    path: "/services",
    name: "services",
    component: function () {
      return import("../views/ServicesView.vue");
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: function () {
      return import("../views/ContactView.vue");
    },
  },
  {
    path: "/team",
    name: "team",
    component: function () {
      return import("../views/TeamView.vue");
    },
  },
  {
    path: "/editor",
    name: "editor",
    component: function () {
      return import("../views/BlogEditor.vue");
    },
  },
  {
    path: "/login",
    name: "login",
    component: function () {
      return import("../views/LoginEditor.vue");
    },
  },
  {
    path: "/blogs",
    name: "blogs",
    component: function () {
      return import("../views/BlogsView.vue");
    },
  },
  {
    path: "/blog/:id",
    name: "blog",
    component: function () {
      return import("../views/BlogView.vue");
    },
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
