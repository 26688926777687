import { createStore } from 'vuex'

export default createStore({
  state: {
    blogs: [
      {
        blog_id: '1',
        title: 'Blog 1',
        desc: 'Boost your product and service\'s credibility by adding testimonials from your clients. People love recommendations so feedback from others who\'ve tried it is invaluable',
        author: 'TEDDY YUHUI',
        pub_date: '28-5-2024',
        thumbnail: '',
        content: '',
        tags: ['tag1', 'tag2', 'tag3']
      },
      {
        blog_id: '1',
        title: 'Blog 1',
        desc: 'Boost your product and service\'s credibility by adding testimonials from your clients. People love recommendations so feedback from others who\'ve tried it is invaluable',
        author: 'SARI PURDUE',
        pub_date: '15:3:2024',
        thumbnail: '',
        content: '',
        tags: ['tag1', 'tag2', 'tag3']
      },
      {
        blog_id: '1',
        title: 'Blog 1',
        desc: 'Boost your product and service\'s credibility by adding testimonials from your clients. People love recommendations so feedback from others who\'ve tried it is invaluable',
        author: 'MATT ZHANGYEE',
        pub_date: '1-6-2024',
        thumbnail: '',
        content: '',
        tags: ['tag1', 'tag2', 'tag3']
      }
    ],
    user: null,
    notification: { message: '', type: '' },
    menuOpen: false
  },
  getters: {
    BLOGS (state) {
      return state.blogs
    },
    USER (state) {
      return state.user
    },
    NOTIF (state) {
      return state.notification
    },
    MENU_OPEN (state) {
      return state.menuOpen
    }
  },
  mutations: {
    addBlogs (state, payload) {
      for(let i = 0; i < payload.length; i++) {
        state.blogs.push(payload[i])
      }
    },
    setBlogs (state, payload) {
      state.blogs = [...payload]
    },
    clearBlogs (state) {
      state.blogs.splice(0, state.blogs.length)
    },
    setUser (state, payload) {
      state.user = payload
    },
    clearUser (state) {
      state.user = null
    },
    setNotification (state, payload) {
      state.notification = payload
    },
    clearNotification (state) {
      state.notification = { message: '', type: '' }
    },
    toggleMenu (state, payload) {
      state.menuOpen = payload
    }
  },
  actions: {
    SET_BLOGS (context, payload) {
      context.commit('setBlogs', payload)
    },
    ADD_BLOGS (context, payload) {
      context.commit('addBlogs', payload)
    },
    CLEAR_BLOGS (context) {
      context.commit('clearBlogs')
    },
    SET_USER (context, payload) {
      context.commit('setUser', payload)
    },
    CLEAR_USER (context) {
      context.commit('clearUser')
    },
    SET_NOTIF (context, payload) {
      context.commit('setNotiication', payload)
    },
    CLEAR_NOTIF (context) {
      context.commit('clearNotification')
    },
    TOGGLE_MENU (context, payload) {
      context.commit('toggleMenu', payload)
    }
  },
  modules: {
  }
})
