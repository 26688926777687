/* This file contains list, array and set definitions that may be imported into dropdown menus or page content */

export const services = [
    { title: 'CRIMINAL LITIGATION', thumbnail: 'criminal_law', explanation: 'Our firm offers robust criminal litigation services, defending clients accused of various offences. With an understanding of the Kenyan judicial system, we ensure that your rights are safeguarded throughout the legal process.' },
    { title: 'DEBT RECOVERY', thumbnail: 'debt_recovery', explanation: 'We specialize in debt recovery, helping our clients reclaim outstanding debts effectively. Our strategic approach includes negotiating settlements and pursuing legal action when necessary, ensuring prompt recovery.' },
    { title: 'LANDLORD & TENANCY LAW', thumbnail: 'tenancy_law', explanation: 'We provide comprehensive legal guidance on lease agreements, disputes, and evictions, ensuring that both landlords and tenants understand their rights and obligations.' },
    { title: 'COMMERCIAL DISPUTES', thumbnail: 'commercial_law', explanation: 'Our team is adept at handling commercial disputes that may arise between businesses. We offer mediation and litigation services to resolve conflicts efficiently, protecting our clients\' interests.' },
    { title: 'EMPLOYMENT & LABOUR RELATIONS', thumbnail: 'labour_law', explanation: 'We provide legal counsel on employment contracts, workplace policies, and dispute resolution, advocating for fair treatment and compliance with labor regulations.' },
    { title: 'ENVIRONMENT & LAND DISPUTES', thumbnail: 'environmental_law', explanation: 'Environmental and land disputes require specialized expertise. Our firm assists clients in navigating complex regulations and resolving issues related to land ownership and environmental compliance.' },
    { title: 'CIVIL LITIGATION', thumbnail: 'civil_law', explanation: 'We handle a wide range of civil litigation cases, from personal injury claims to contractual disputes. Our goal is to advocate for our clients vigorously while seeking fair compensation through the judicial system.' },
    // { title: '', explanation: '' },
    // { title: '', explanation: '' },
    // { title: '', explanation: '' },
    // { title: '', explanation: '' },
    // { title: '', explanation: '' },
    // { title: '', explanation: '' },
    // { title: '', explanation: '' },
    // { title: '', explanation: '' }
]