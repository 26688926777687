<script>
export default {
    name: 'FooterSection'
}
</script>

<template>
    <footer>
      <a href="mailto: obwatsamd@gmail.com">Contact Support</a>
      <a href="https://bysonicgroup.com">Designed by BySonic&copy;</a>
    </footer>
</template>

<style>
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding: 1rem 2rem; */
  background: black;
}
footer a {
  color: antiquewhite;
  text-decoration: none;
  margin: 1rem 2rem;
}
</style>