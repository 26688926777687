<script>
import TopNav from '@/components/TopNav.vue'
import FooterSection from '@/components/FooterSection.vue'
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  components: { TopNav, FooterSection },
  data () {
    return {
      form: { fname: '', oname: '', email: '', phone: '' }
    }
  },
  computed: {
    formValidated () {
      return this.form.email !== '' && this.form.phone !== '' && this.form.fname !== '' && this.form.oname !== ''
    },
    ...mapGetters({
      menuOpen: 'MENU_OPEN'
    })
  },
  methods: {
    animateContent () {
      const faders = document.querySelectorAll('.fade-in')
      const sliders = document.querySelectorAll('.slide-in')
      const scrollAppearptions = { threshold: 0, rootMargin: '0px 0px -200px 0px' }
      const appearnScroll = new IntersectionObserver(function(entries, appearnScroll) {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            return
          } else {
            entry.target.classList.add('active')
            appearnScroll.unobserve(entry.target)
          }
        })
      }, scrollAppearptions)
      faders.forEach(fader => appearnScroll.observe(fader))
      sliders.forEach(slider => appearnScroll.observe(slider))
    },
    toggleMenu (status) {
      this.$store.dispatch('TOGGLE_MENU', status)
    },
    changeMenu () {
      this.menuOpen ? document.getElementById('nav-items').classList.add('open') : document.getElementById('nav-items').classList.remove('open')
    },
    openModal () {
      const modal = document.querySelector('#modal')
      modal.showModal()
    },
    closeModal () {
      const modal = document.querySelector('#modal')
      modal.setAttribute('closing', '')
      modal.addEventListener('animationend', () => {
        modal.removeAttribute('closing')
        modal.close()
      }, { once: true })
    },
    async submitForm () {
      const formData = new FormData()
      formData.append('name', this.form.fname + ' ' + this.form.oname)
      formData.append('email', this.form.email)
      formData.append('phone', this.form.phone)
      const response = await axios({
        method: 'POST',
        url: './api/Admin.php?action=mail',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      response.data.error ? console.log(response.data.message) : this.article = response.data.article
    },
  },
  mounted () {
    this.animateContent()
  },
  watch:{
    $route (){
      this.toggleMenu(false)
    },
    menuOpen () {
      this.changeMenu()
    }
  } 
}
</script>

<template>
  <div class="main">
    <TopNav :menuOpen="menuOpen" @toggle-menu="toggleMenu" />

    <router-view/>

    <dialog class="modal" id="modal">
      <div class="modal-content">
        <span>WE'LL REACH OUT TO YOU</span>
        <div class="form">
          <input type="text" placeholder="First Name" v-model="form.fname" required />
          <input type="text" placeholder="Other Name(s)" v-model="form.oname" required />
          <input type="email" placeholder="email@example.com" v-model="form.email" required />
          <input type="tel" placeholder="(+254)712 345 678" v-model="form.phone" required />
        </div>
        <div class="buttons">
          <button @click="submitForm" :disabled="formValidated" style="background: var(--btn-primary); color: azure;">Submit Request</button>
          <button @click="closeModal" style="background: var(--btn-danger); color: azure;">Cancel</button>
        </div>
      </div>
    </dialog>
  </div>

  <FooterSection />
</template>

<style>
@import url('./assets/styles/style.css');

.main {
  width: 100%;
  min-height: 100dvh;
  margin: 0 0 .75rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
section {
  max-width: 1280px;
  width: 80rem;
  display: flex;
  margin-inline: auto;
}
span {
  font-family:Georgia, 'Times New Roman', Times, serif;
  padding: 0;
  letter-spacing: 0em;
  line-height: 2rem;
  font-weight: 600;
  font-size: 2.5rem;
}
#contact button, #landing button {
  font-size: 1.1rem;
  color: rgb(231, 224, 224);
  padding: .8rem 2.4rem;
  margin-block-start: 2.6rem;
  background-color: var(--btn-primary);
  &:hover {
    background-color: rgba(247, 137, 12, 0.87);
  }
}
section.full {
  background: var(--bg-secondary);
  box-shadow: 0 0 0 100vmax var(--bg-secondary);
  clip-path: inset(0 -100vmax);
  flex-direction: column;
}
section:nth-child(1), section:nth-child(2) {
  flex-direction: row;
  flex-wrap: nowrap;
}

.modal {
  box-shadow: 3px 3px 10px rgba(26, 26, 26, 0.5), 0px 0px 0px 100vw rgba(26, 26, 26, 0.6);
  border: 0;
  border-radius: .25rem;
  padding: 0;
}
.modal[open] {
  animation: slide-up 300ms forwards, fade-in 500ms forwards;
}
.modal[open]::backdrop {
  animation: fade-in 500ms forwards;
}
.modal[closing] {
  display: block;
  pointer-events: none;
  inset: 0;
  animation: fade-out 500ms forwards;
}
.modal-content {
  padding: 4rem 2rem 1rem;
}
.modal .form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
  padding-block: 2rem;
}
.modal .form > input {
  padding: .7rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
  border-radius: 6px;
}
.modal button {
  padding: .5rem 2rem;
  margin: 1rem 2em;
}
/* Animations */
.fade-in {
  opacity: 0;
  transition: opacity 550ms ease-in;
}

.fade-in.active {
  opacity: 1;
}

.slide-in {
  transform: translateY(-50%);
  transition: transform 300ms ease-in-out;
}
.slide-in.active {
  transform: translateY(0);
}

@media (width <= 720px) {
  main {
    padding-inline: 1rem;
  }
  section {
    max-width: 100dvw;
    width: fit-content;
  }
}

@media (width <= 640px) {
  main {
    padding-inline: 0;
  }
  section {
    max-width: 100dvw;
    /* width: 100%; */
  }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out {
  100% { opacity: 0; }
  0% { opacity: 1; }
}

@keyframes slide-in {
  0% { transform: translateY(100%); }
  100% { transform: translateY(0); }
}
</style>
