<!-- Home -->
<script>
import { services } from '@/assets/scripts/resources'

export default {
  name: 'HomeView',
  computed: {
    windowSize () {
      let device = ''
      window.innerWidth < 720 ? device = 'mobile' : device = 'desktop'
      return device
    }
  },
  data () {
    return {
      serviceList: services.map(service => service.title),
      serviceIndex: 0,
      isDeleting: false,
      typingSpeed: 100,
      deletingSpeed: 50,
      delayBetweenItems: 3000
    }
  },
  methods: {
    animateContent () {
      const faders = document.querySelectorAll('.fade-in')
      const sliders = document.querySelectorAll('.slide-in')
      const scrollAppearptions = { threshold: 0, rootMargin: '0px 0px -270px 0px' }
      const appearnScroll = new IntersectionObserver(function(entries, appearnScroll) {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            return
          } else {
            entry.target.classList.add('active')
            appearnScroll.unobserve(entry.target)
          }
        })
      }, scrollAppearptions)
      faders.forEach(fader => appearnScroll.observe(fader))
      sliders.forEach(slider => appearnScroll.observe(slider))
    },
    // animateScrollText() {
    //   const serviceSpans = document.getElementsByClassName('service-span')
    //   for (let i = 0; i < this.serviceList.length; i++) {
    //     serviceSpans[i].classList.remove("text-in", "text-out");
    //   }
    //   serviceSpans[this.serviceIndex].classList.add("text-in");

    //   setTimeout(() => {
    //     serviceSpans[this.serviceIndex].classList.add("text-out");
    //   }, 2500);

    //   setTimeout(() => {
    //     if (this.serviceIndex === this.serviceList.length - 1) {
    //       this.serviceIndex = 0;
    //     } else {
    //       this.serviceIndex++;
    //     }
    //     this.animateScrollText();
    //   }, 3000);
    // },
    // animateTypingText() {
    //   const currentItem = this.serviceList[this.serviceIndex];
    //   let charIndex = currentItem.length
    //   const displayText = this.isDeleting ? currentItem.substring(0, charIndex--) : currentItem.substring(0, charIndex++);

    //   document.getElementById('typing').textContent = displayText;

    //   if (!this.isDeleting && charIndex === currentItem.length) {
    //       setTimeout(() => this.isDeleting = true, this.delayBetweenItems);
    //   } else if (this.isDeleting && charIndex === 0) {
    //       this.isDeleting = false;
    //       this.serviceIndex = (this.serviceIndex + 1) % this.serviceList.length;
    //   }
    //   setTimeout(this.animateTypingText, this.isDeleting ? this.erasingSpeed : this.typingSpeed);
    // },
    goToAbout () {
      document.getElementById('about').scrollIntoView({ behavior: 'smooth' });
    }
  },
  mounted () {
    this.animateContent()
  }
}
</script>

<template>
  <section id="landing">
    <div class="landing__info">
      <div class="logo">
        <picture>
          <source srcset="@/assets/logos/dark-logo.png" media="(prefers-color-scheme: dark)" alt="Maobe Mukhwana & Company" width="100" height="100" class="fade-in" />
          <img src="@/assets/logos/logo.png" alt="Maobe Mukhwana & Company" width="100" height="100" class="fade-in" />
        </picture>
        <h2 class="fade-in finlandica-bold">Maobe Mukhwana & Company Advocates</h2>
      </div>
      <div class="glass">
        <span class="fade-in title-text">LEGAL EXCELLENCE WITH A PERSONAL TOUCH</span>
      <p style="margin-inline: 7rem;" class="fade-in">Offering dedicated professionalism and consultancy in civil, criminal, commercial and real-estate law.</p>
      </div>
      <button @click="goToAbout" class="fade-in"> GET TO KNOW US</button>
    </div>
    <div class="landing__image">
      <img src="@/assets/images/mukhwana.jpg" alt="Main Lawyer" lazy-loading="true"  class="fade-in"/>
    </div>
  </section>

  <section id="about">
    <img src="@/assets/images/certificate.png" alt="About Lawfirm" lazy-loading="true" class="fade-in" />
    <div class="about__info">
      <span class="fade-in slide-in"> WHO WE ARE</span>
      <div>
        <p class="fade-in slide-in">We are a dynamic and medium-sized law firm with a presence in two strategic locations; the Head Office at the Walias Center Kakamega on the 2nd Floor, Suite No.205 Along the Kakamega Kisumu Highway and the other in Kimilili at Alimo Investment House, near the District Commisioner's office.</p>
        <p class="fade-in slide-in">Our team is led by our dedicated Managing Partner/Director, Ms. Maobe Winnie Mukhwana. She is joined by our proficient Associate Counsel, Ms. Ritah Shatsia Shabola.</p>
        <hr>
        <p class="fade-in slide-in">Ms. MAOBE WINNIE MUKHWANA is an esteemed Advocate of the High Court of Kenya and a proud member of the Law Society of Kenya. With 8 years of rich practice experience, she is the pillar of our firm.</p>
        <p class="fade-in slide-in"> Winnie wears many hats; she is a Legal Consultant,  Pro Bono Lawyer for Minority Groups and a Seasoned Litigator. Her expertise includes Land and Environment Law, Property Law, Conveyancing Transactions, Criminal Litigation, Civil Litigation and Employment and Labour Relations. Additionally, she consults for various institutions, sharing her extensive knowledge and insights.</p>
      </div>
      <button @click="openModal" class="fade-in slide-in">EXPERIENCE LEGAL EXCELLENCE</button>
    </div>
  </section>
</template>

<style>
section#landing > div.landing__info, section#about > div.about__info {
  min-width: 40rem;
  width: 100%;
  padding: 3rem 0;
  transition: all 350ms ease-in-out;
}
section#landing > div.landing__image {
  min-width: 40rem;
  width: 100%;
  height: 100%;
  background-color: var(--btn-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}
section#landing > div.landing__image > img {
  width: 25.2rem;
  height: 36rem;
  object-fit: cover;
  object-position: center;
}
section#landing > div.landing__info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
section#landing > div.landing__info > div.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  margin-block-end: 4rem;
}
section#landing > div.landing__info > div.logo > h4 {
  width: 16rem;
  margin: 0;
}
.glass {
  margin: 0 .5rem 1rem;
  padding: 2rem .5rem;
  background: rgba(94, 245, 7, 0.055);
  box-shadow: 0 4px 3px rgba(31, 135, 31, 0.37);
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
}
.title-text {
  background: radial-gradient(
    circle at 100%,
    #616161,
    #333333 50%,
    #686868 75%,
    #2e2e2e 75%
  );
  font-weight: 600;
  background-size: 200% auto;
  color: #583615;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: glowText 25.5s linear infinite;
}

section#about > img {
  width: 40rem;
  height: 100%;
  object-fit: cover;
}
section#about > div.about__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  background: rgba(228, 148, 0, 0.521);
  background: linear-gradient(rgba(255, 251, 244, 0.2) 0%, rgba(255, 215, 142, 0.5) 45%, rgba(255, 187, 0, 0.5) 100%);
}
section#about > div.about__info > div { margin-inline: 3rem; }
section#about > div.about__info > button { 
  margin-inline: 3rem;
  padding-inline: 1.2rem;
  text-align: start;
  width: fit-content;
  /* transition: all 300ms ease-in-out; */
  &:hover {
    background-color: var(--btn-primary);
  }
}
section#about > div.about__info > span {
  margin-inline-start: 3rem;
}
div.offers { 
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
}
span.scroll-text {
  height: 46px;
  overflow: hidden;
  span.service-span {
    font-family:  "Bodoni Moda SC", serif;
    font-size: 20px;
    color: var(--theme-light);
    font-weight: 700;
    line-height: 46px;
    display: none;
    &.text-in{
      display: block;
      animation: textScrollIn .5s ease;
    }
    &.text-out{
      animation: textScrollOut 1s ease;
    }
  }
}


@media (width >= 640px) and (width <= 1280px) {
  section#landing, section#about {
    flex-direction: column;
    margin-inline: auto;
    align-items: flex-start;
    justify-content: center;
    width: fit-content;
    max-width: 100dvw;
  }
  section#about > img {
    align-self: auto;
    margin: 0 auto;
  }
}

@media (width < 640px) {
  section#landing, section#about {
    flex-direction: column;
    margin: 0;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    max-width: 100dvw;
  }
  section#landing *, section#about * {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 100dvw;
  }
  section#about > div.about__info > span {
    margin: 0;
  }
  section#landing > div.landing__image, section#landing > div.landing__info {
    min-width: fit-content;
  }
  section#landing > div.landing__info > p {
    margin: 1.2rem auto !important;
    min-width: 20rem;
  }
  section#landing > div.landing__info > button {
    margin-block-start: 2rem;
    padding: .3em 1em !important;
    /* min-width: 20rem; */
  }
  section#about > div.about__info {
    justify-content: space-around;
    align-items: center;
    min-width: fit-content;
    max-width: 100dvw;
    &>div, div * {
      margin: .5rem 0 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }
  }
  span.scroll-text {
    span.service-span {
      font-size: 2rem;
    }
  }
}

@keyframes glowText {
  to {
    background-position: 200% center;
  }
}
</style>